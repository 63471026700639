import React from 'react';
import { useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { Typography } from '../../constants/typography';
import { selectIsMobile } from '../../state/reducers/utils';

const AboutUsMission = ({ content }) => {
  const isMobile = useSelector(selectIsMobile);
  return (
    <div
      id="our-mission"
      className={`
        ${Constants.panelBase}
        dark-header header-section
        flex flex-col items-start justify-end 
        px-8 pb-32 lg:p-16 lg:py-24 
        h-screen
        w-full
        bg-tan
      `}
    >
      <h2
        className={`
          ${Typography.h1} 
          ${Typography.strokedCharcoal}
          text-left
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '5%' : '5%'}
        data-scroll-repeat="true"
      >
        {content.outlineText}
      </h2>
      <p
        className={`
          ${Typography.h3Alt}
          mt-8 lg:mt-14
          w-[95%] max-w-[979px]
          text-charcoal 
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '5%' : '5%'}
        data-scroll-repeat="true"
      >
        {content.bodyText}
      </p>
    </div>
  );
};

export default AboutUsMission;
