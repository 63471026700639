import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { Typography } from '../../constants/typography';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { selectIsMobile, selectScrollReady } from '../../state/reducers/utils';

const MediaItem = ({ style, className, data }) => {
  const parentEl = useRef();
  const videoEl = useRef();
  const src = data.video.localFile.publicURL;
  const [isVisible, setIsVisible] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  useIntersectionObserver({
    target: parentEl,
    rootMargin: '0px',
    threshold: 0,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting) {
        setIsVisible(true);
        setIsPaused(false);
      } else {
        setIsPaused(true);
      }
    },
  });
  useEffect(() => {
    const vid = videoEl.current;
    if (vid) {
      if (!isPaused) {
        const playPromise = vid.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Automatic playback started!
          }).catch(() => {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          });
        }
      } else {
        vid.pause();
      }
    }
  }, [isPaused]);
  return (
    <div style={{ ...style }} ref={parentEl} className={`${className}`}>
      { isVisible && (
        <video
          ref={videoEl}
          playsInline
          loop
          muted
          autoPlay
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        >
          <source src={src} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

const Mantra = ({ mantra, index, isMobile }) => {
  let classes;
  const parentEl = useRef(null);
  switch (index) {
    case 0:
      classes = {
        scrollSpeed: 0.4,
        wrapper: 'justify-start lg:justify-center mb-16 lg:mb-48',
        inner: 'flex-col lg:flex-row items-start lg:items-center',
        image: 'lg:mr-8 mb-8 lg:mb-0 w-[220px] h-[150px] lg:w-[340px] lg:h-[230px] rounded-[28px]',
        body: 'max-w-[284px]',
      };
      break;
    case 1:
      classes = {
        scrollSpeed: 0.3,
        wrapper: 'justify-end mb-32 lg:mb-40',
        inner: 'flex-col lg:flex-row-reverse text-right items-end lg:items-center',
        image: 'sm:rounded-[46px] tp:rounded-[46px] rounded-l-[46px] rounded-r-none w-[350px] h-[250px] -mr-8 lg:mr-0 lg:ml-18 mb-8 lg:mb-0 lg:w-[478px] lg:h-[380px]',
        content: 'items-end',
        body: 'max-w-[240px] lg:max-w-[340px]',
        outlineText: 'max-w-[500px]',
      };
      break;
    case 2:
      classes = {
        scrollSpeed: 0.5,
        wrapper: 'justify-start tl:ml-12 lg:ml-24 mb-32 lg:mb-40',
        inner: 'flex-col lg:flex-row text-left lg:items-center',
        image: 'mb-8 lg:mb-0 lg:mr-14 w-[315px] h-[215px] lg:w-[560px] lg:h-[380px] rounded-[32px]',
        content: 'items-start',
        body: 'max-w-[280px] lg:max-w-[340px]',
        outlineText: 'max-w-[500px]',
      };
      break;
    default:
      classes = {
        scrollSpeed: 0.4,
        wrapper: 'justify-center mb-20',
        inner: 'flex-col text-center items-center',
        image: 'mb-8 lg:mb-10 w-[230px] h-[150px] lg:w-[315px] lg:h-[215px] rounded-[30px]',
        content: 'items-center',
        body: 'mt-4 max-w-[240px] lg:max-w-full',
        outlineText: 'max-w-[500px] lg:max-w-[650px]',
      };
      break;
  }
  return (
    <div className={`flex ${classes.wrapper}`} ref={parentEl}>
      {
        isMobile !== null && (
          <div
            className={` flex ${classes.inner} ${Constants.fadeUp}`}
            data-scroll
            data-scroll-class="fade-up-visible"
            data-scroll-offset={isMobile ? '25%' : '40%'}
            data-scroll-repeat="true"
          >
            <div className={`origin-center overflow-hidden rounded-video ${classes.image}`}>
              <MediaItem
                data={mantra}
                className="w-full h-full"
              />
            </div>
            <div
              className={`
                flex flex-col
                ${classes.content}
              `}
            >
              <h3 className={`${Typography.h3} leading-[42px] lg:leading-[55px]`}>
                <span className="block whitespace-nowrap">{mantra.titleFill}</span>
                <span className={`block ${Typography.strokedWhite} ${classes.outlineText}`}>{mantra.titleOutline}</span>
              </h3>
              <p className={`font-sans text-[24px] leading-[120%] ${classes.body} mt-5 lg:mt-7`}>{mantra.subtitle}</p>
            </div>
          </div>
        )
      }
    </div>
  );
};

const AboutUsMantras = ({ content }) => {
  const mantras = useRef(null);
  const scrollReady = useSelector(selectScrollReady);
  const isMobile = useSelector(selectIsMobile);
  const { title, contentBlock } = content;
  const [scrollOffset, setScrollOffset] = useState('');
  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      setScrollOffset(
        isMobile
          ? `${parseInt(mantras.current.offsetHeight + (window.innerHeight * 0.45), 10)},-${window.innerHeight}`
          : `${parseInt(mantras.current.offsetHeight + (window.innerHeight * 0.75), 10)},-${window.innerHeight}`,
      );
    }
  }, [scrollReady]);
  useEffect(() => {
    if (scrollOffset) {
      window.locoScroll.update();
    }
  }, [scrollOffset]);

  return (
    <div
      ref={mantras}
      id="our-mantras"
      className={`
        light-header header-section
        relative
        w-screen min-h-screen
        py-32 lg:pt-48
        text-white
        join-us-transition
      `}
    >
      <h2
        className={`
          ${Typography.h1}
          mb-24 lg:mb-40
          px-8 lg:px-16
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '25%' : '25%'}
        data-scroll-repeat="true"
      >
        {title}
      </h2>
      <div className="flex flex-col px-8 lg:px-0">
        {contentBlock.map((mantra, index) => (
          <Mantra
            key={`matra-about-us-${mantra.titleFill}-${mantra.titleOutline}`}
            mantra={mantra}
            index={index}
            isMobile
          />
        ))}
      </div>
    </div>
  );
};

export default AboutUsMantras;
