/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */
import gsap, { SplitText } from 'gsap/all';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { Colors } from '../../constants/colors';
import { Typography } from '../../constants/typography';
import {
  selectIsLandscape,
  selectIsMobile,
  selectIsTablet,
  selectScrollReady,
  setHeaderColorOverride,
  setHideHeader,
} from '../../state/reducers/utils';
import ScrollButton from '../buttons/scroll';

gsap.registerPlugin(SplitText);

const AboutUsHero = ({ content }) => {
  const dispatch = useDispatch();
  const currentIndex = useRef(0);
  const scrollY = useRef(0);
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const isLandscape = useSelector(selectIsLandscape);
  const [current, setCurrent] = useState(0);
  const [maxHeight, setMaxHeight] = useState(null);
  const [stickyProps, setStickyProps] = useState({});
  const panel1 = useRef(null);
  const panel2 = useRef(null);
  const tl = useRef(null);
  const hero = useRef(null); 
  const scrollReady = useSelector(selectScrollReady);
  const animationComplete = () => {
    if (isMobile || isTablet) return;
    setMaxHeight(scrollY.current + window.innerHeight);
  };

  useEffect(() => {
    if (isMobile === null || isTablet === null) return;
    if (!isMobile && !isTablet) {
      setStickyProps({
        'data-scroll': true,
        'data-scroll-sticky': !isTablet && !isMobile,
        'data-scroll-target': '#brands-hero',
      });
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (current === 0) {
      dispatch(setHideHeader(false));
    }
    if (isMobile || isTablet || isMobile === null) return;
    setTimeout(() => dispatch(setHeaderColorOverride(current === 0 ? 'text-white' : 'text-charcoal')), 500);
  }, [current, isMobile, isTablet]);

  useEffect(() => {
    if (window.locoScroll) {
      window.locoScroll.update();
    }
    if (maxHeight && !isMobile) {
      dispatch(setHideHeader(true));
    }
  }, [maxHeight]);

  useEffect(() => {
    if (isMobile === null || isTablet === null || tl.current) return;
    dispatch(setHeaderColorOverride('text-white'));
    tl.current = gsap.timeline({ onComplete: animationComplete });
    const firstSplit = new SplitText(panel1.current.querySelector('h1'), { type: 'words,chars' });
    const secondSplit = new SplitText(panel2.current.querySelector('h1'), { type: 'words,chars' });
    const baseDelay = 0.4;

    // Enter 1
    tl.current.from(firstSplit.words, {
      duration: 0.8, y: 50, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
    }, baseDelay);
    if (isMobile) {
      tl.current.from(panel1.current.querySelector('.scroll-button-wrapper'), {
        duration: 0.6, y: 100, alpha: 0, ease: 'expo.inOut',
      }, '-=0.5');
    }
    tl.current.addLabel('endIn');

    // Exit 1
    if (!isMobile && !isTablet) {
      tl.current.to(firstSplit.words, {
        duration: 0.6, alpha: 0, y: -50, stagger: 0.05, ease: 'expo.inOut',
      });
    }

    // Transition
    if (!isMobile && !isTablet) {
      tl.current.to(hero.current, {
        backgroundColor: Colors.yellow, duration: 0.4,
      }, '-=0.2');
      tl.current.to(hero.current.querySelector('.scroll-button'), {
        color: Colors.charcoal, duration: 0.4,
      }, '-=0.4');
    }

    // Enter 2
    tl.current.from(secondSplit.words, {
      duration: 0.6, y: 50, alpha: 0, stagger: 0.05, ease: 'expo.inOut',
    }, isTablet || isMobile ? '-=0' : '-=0.4');
    tl.current.from(panel2.current.querySelector('p'), {
      duration: 0.6, y: 50, alpha: 0, ease: 'expo.inOut',
    }, '-=0.6');
    if (isMobile) {
      tl.current.from(panel2.current.querySelector('.scroll-button-wrapper'), {
        duration: 0.6, y: 100, alpha: 0, ease: 'expo.inOut',
      }, '-=0.5');
    }
    tl.current.addLabel('endOut');
    tl.current.pause();
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (isMobile === null || isTablet === null) return;
    if (current === 0) {
      setMaxHeight(null);
      tl.current.tweenTo('endIn');
    } else if (current === 1) {
      tl.current.tweenTo('endOut');
    }
  }, [current, isMobile, isTablet]);

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.on('scroll', (e) => {
        scrollY.current = e.scroll.y;
        if (e.scroll.y > window.innerHeight * (isMobile ? 0.45 : isTablet ? 0.5 : 0.25)) {
          if (currentIndex.current !== 1) {
            currentIndex.current = 1;
            setCurrent(1);
          }
        } else {
          if (currentIndex.current !== 0) {
            setCurrent(0);
            currentIndex.current = 0;
            tl.current.tweenTo('endIn');
          }
        }
      });
    }
  });

  return (
    <div className="bg-charcoal relative overflow-hidden tl:h-auto lg:h-[500vh]" style={{ maxHeight }} ref={hero} id="brands-hero">
      <div
        className={`
          ${!isTablet && 'lg:!absolute'}
          top-0
          left-0
          ${Constants.panelBase}
          sm:responsive-h-screen
          tp:responsive-h-screen
          tl:responsive-h-screen
          flex justify-center items-center
          px-6 pb-16 lg:p-16 
          z-10
          ${(isMobile || isTablet) && 'bg-charcoal light-header header-section'}
          text-yellow
        `}
        ref={panel1}
        {...stickyProps}
      >
        <h1 className={`${Typography.h1} ${Typography.strokedWhite} text-center sd:max-w-[80%] max-w-[875px]`}>
          {content.outlineText}
        </h1>
        {
          (isMobile || isTablet) && (
            <div
              className={`scroll-button ${isTablet && isLandscape ? Constants.stickyScrollButton : Constants.stickyScrollButtonMobile}`}
            >
              <ScrollButton
                location='aboutus'
                arrowNo={current+1}
                dynamic
                positionClassName="relative"
                colorClassName="text-white"
                scrollTo="our-mission"
              />
            </div>
          )
        }
      </div>
      <div
        className={`
          ${!isTablet && 'lg:!absolute'}
          top-0
          left-0
          ${Constants.panelBase}
          tl:responsive-h-screen
          tp:responsive-h-screen
          tl:responsive-h-screen
          flex items-end justify-start 
          px-3 pb-16 lg:p-16 
          z-10
          ${(isMobile || isTablet) && 'bg-yellow dark-header header-section'}

        `}
        {...stickyProps}
      >
        <div
          className="w-full h-full flex flex-col justify-center items-center text-charcoal"
          ref={panel2}
        >
          <h1 className={`${Typography.h1} text-charcoal text-center w-[95%] max-w-[916px]`}>
            {content.fillText}
          </h1>
        </div>
        {
          (isMobile || isTablet) && (
            <div
              className={`scroll-button ${isTablet && isLandscape ? Constants.stickyScrollButton : Constants.stickyScrollButtonMobile}`}
            >
              <ScrollButton
                location='aboutus'
                arrowNo={current+1}
                dynamic
                positionClassName="relative"
                colorClassName="text-charcoal"
                scrollTo="our-mission"
              />
            </div>
          )
        }
      </div>
      {
        (!isMobile && !isTablet) && (
          <div
            className={Constants.stickyScrollButton}
            {...stickyProps}
          >
            <ScrollButton
              location='aboutus'
              arrowNo={current+1}
              dynamic
              positionClassName="relative"
              colorClassName="text-white"
              scrollTo="our-mission"
            />
          </div>
        )
      }
    </div>
  );
};

export default AboutUsHero;
