import gsap, { ScrollTrigger } from 'gsap/all';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Constants } from '../../constants';
import { selectIsMobile, selectScrollReady, setHeaderColorOverride } from '../../state/reducers/utils';
import IconAsteriskOutline from '../icons/asterisk-outline';

gsap.registerPlugin(ScrollTrigger);

const AboutUsAsterisks = ({ setColor }) => {
  const dispatch = useDispatch();
  const tl = useRef(null);
  const st = useRef(null);
  const isMobile = useSelector(selectIsMobile);
  const asterisksWrapper = useRef(null);
  const asterisk1 = useRef(null);
  const asterisk2 = useRef(null);
  const asterisk3 = useRef(null);
  const scrollReady = useSelector(selectScrollReady);
  const animateAsterisks = () => {
    tl.current = gsap.timeline();
    tl.current.from(asterisk1.current, {
      duration: 1,
      marginTop: isMobile ? -175 : -250,
    });
    tl.current.from(asterisk2.current, {
      duration: 1,
      scale: 0.85,
    }, '-=1');
    tl.current.from(asterisk3.current, {
      duration: 1,
      marginTop: isMobile ? 175 : 250,
      scale: 0.7,
    }, '-=1');

    st.current = ScrollTrigger.create({
      trigger: asterisksWrapper.current,
      animation: tl.current,
      scroller: '#___gatsby',
      scrub: 1,
      start: isMobile ? 'top bottom-=5%' : 'top bottom',
      end: 'center center-=25%',
      id: 'asterisks',
      onUpdate: (e) => {
        if (e.progress === 1) {
          setColor('text-charcoal');
          dispatch(setHeaderColorOverride('text-charcoal'));
        } else {
          setColor('text-white');
          dispatch(setHeaderColorOverride('text-white'));
        }
      },
    });
  };

  const killAnimation = () => {
    st.current.kill();
    tl.current.pause(0).kill(true);
    tl.current = null;
    gsap.set(asterisk2.current, { clearProps: true });
    gsap.set(asterisk1.current, { clearProps: true });
    gsap.set(asterisk3.current, { clearProps: true });
  };

  useEffect(() => {
    if (!scrollReady || !window.locoScroll) return;
    window.locoScroll.on('scroll', (e) => {
      if (
        asterisksWrapper.current
        && e.scroll.y >= asterisksWrapper.current.offsetTop - (window.innerHeight * 2)
      ) {
        if (!tl.current) {
          animateAsterisks();
        }
      } else if (tl.current) {
        killAnimation();
      }
    });
  }, [scrollReady]);
  return (
    <div className="relative w-full block bg-transparent h-[350px] tp:h-[400px] lg:h-[400px] mt-[-5vh]" ref={asterisksWrapper}>

      <div
        className={`${Constants.absoluteCenter} block w-[400px] lg:w-[626px] tp:w-[626px]`}
        ref={asterisk1}
      >
        <IconAsteriskOutline className="asterisk-outline-perspective-stack" pathClassName="text-white" />
      </div>
      <div
        className={`${Constants.absoluteCenter} block w-[400px] lg:w-[626px] tp:w-[626px]`}
        ref={asterisk2}
      >
        <IconAsteriskOutline className="asterisk-outline-perspective-stack" pathClassName="text-white" />
      </div>
      <div
        className={`${Constants.absoluteCenter} block w-[400px] lg:w-[626px] tp:w-[626px]`}
        ref={asterisk3}
      >
        <IconAsteriskOutline className="asterisk-outline-perspective-stack" pathClassName="text-white" />
      </div>
    </div>
  );
};

export default AboutUsAsterisks;
