import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectIsMobile, selectScrollReady } from '../../state/reducers/utils';
import { Typography } from '../../constants/typography';
import IconAsterisk from '../icons/asterisk';
import PrimaryButton from '../buttons/primary';
import { Constants } from '../../constants';
import { sendTagEvent } from '../../constants/google-tags';

const AboutUsJoinUs = ({ content, color, setColor }) => {
  const scrollReady = useSelector(selectScrollReady);
  const isMobile = useSelector(selectIsMobile);
  const {
    title, subtitle, benefits, buttonA,
  } = content;

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.on('call', (e, dir) => {
        if (e === 'go-white') {
          if (dir === 'enter') {
            setColor('text-charcoal');
          } else {
            setColor('text-white');
          }
        }
      });
    }
  });

  return (
    <div
      id="join-us"
      className={`
        w-full
        h-auto
        min-h-0
        dark-header header-section 
        text-white
        px-8 pt-16 pb-12 tp:py-36 tl:py-18 lg:px-16 lg:py-36 
        flex flex-col lg:justify-center
        relative
        z-20
        join-us-transition
        ${color}
      `}
    >
      <h2
        className={`
          join-us-transition
          ${Typography.h1}
          ${color === 'text-white' ? Typography.strokedWhite : Typography.strokedCharcoal}
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '10%' : '10%'}
        data-scroll-repeat="true"
      >
        {title}
      </h2>
      <h4
        className={`
          ${Typography.h4} 
          max-w-[600px] 
          mt-5 mb-12 lg:mt-16 lg:mb-20
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '10%' : '10%'}
        data-scroll-repeat="true"
      >
        {subtitle}
      </h4>
      <div
        className={`
          flex flex-col tl:flex-col lg:flex-row 
          mb-12 lg:mb-20 
          space-y-8 lg:space-y-0 tl:space-x-0 tl:space-y-8 lg:space-x-28
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset={isMobile ? '10%' : '10%'}
        data-scroll-repeat="true"
      >
        {benefits.map((benefit) => (
          <div
            key={benefit.title}
            className={`
              flex items-start justify-start
            `}
          >
            <IconAsterisk className="flex-0 w-10 lg:w-14 mr-3" pathClassName={`${color} join-us-transition`} />
            <span className="text-[36px] lg:text-[48px] leading-[100%] tl:max-w-full lg:max-w-[240px]">{benefit.title}</span>
          </div>
        ))}
      </div>
      <PrimaryButton
        href={buttonA.link}
        className="join-us-transition"
        color={color === 'text-white' ? 'white' : 'charcoal'}
        target="_blank"
        onClick={() => {
          sendTagEvent('click', {
            event_category: 'About us',
            event_label: 'View all roles',
          });
        }}
      >
        {buttonA.label}
      </PrimaryButton>
    </div>
  );
};

export default AboutUsJoinUs;
