import React from 'react';

const IconAsteriskOutline = ({
  className = 'animate-rotate',
  pathClassName = 'text-charcoal',
}) => (
  <svg className={`${className} w-full`} viewBox="0 0 833.1 814.4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className={`${pathClassName}`}
      strokeWidth="2"
      strokeLinejoin="round"
      strokeLinecap="round"
      stroke="currentColor"
      d="M121,128.8l65.3-54.2L373.7,296V7h85.2v289L646.4,74.6l65.3,54.2L524.2,350.2l287-50.7l14.8,83.1l-287,50.7
    l252.9,144.5L749.3,651L496.9,506.5l99.7,271.6L516.5,807l-99.7-271.6L317.1,807l-79.9-28.9l99.5-271.6L84.5,651l-42.6-73.2
    l252.2-144.5l-287-50.7l14.8-83.1l287,50.7L121,128.8z"
    />
  </svg>
);

export default IconAsteriskOutline;
