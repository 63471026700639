/* eslint-disable no-console */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/app-layout';
import AboutUsHero from '../components/about-us/hero';
import AboutUsMantras from '../components/about-us/mantras';
import AboutUsJoinUs from '../components/about-us/join-us';
import AboutUsMission from '../components/about-us/mission';
import AboutUsAsterisks from '../components/about-us/asterisks';

const MantrasJoinUs = ({ aboutUs }) => {
  const {
    aboutUsD, aboutUsE,
  } = aboutUs;
  const [color, setColor] = useState('text-white');
  return (
    <div className={`transition-all duration-500 overflow-hidden ${color === 'text-white' ? 'bg-charcoal' : 'bg-white'}`}>
      <AboutUsMantras content={aboutUsD} color={color} />
      <AboutUsAsterisks setColor={setColor} />
      <AboutUsJoinUs content={aboutUsE} color={color} />
    </div>
  );
};

const AboutUsPage = ({ data }) => {
  const { aboutUs } = data.aboutUs;

  const {
    aboutUsA, aboutUsB, aboutUsC,
  } = aboutUs;
  return (
    <Layout defaultHeaderColor="text-white">
      <SEO title={data.aboutUs.title} />
      <div className="w-full h-full" data-scroll-section>
        <AboutUsHero
          content={{
            outlineText: aboutUsA.title,
            fillText: aboutUsB.title,
          }}
        />
        <AboutUsMission
          content={{
            outlineText: aboutUsC.title,
            bodyText: aboutUsC.subtitle,
          }}
        />
        <MantrasJoinUs aboutUs={aboutUs} />
      </div>
    </Layout>
  );
};

export default AboutUsPage;

export const aboutUsData = graphql`
  {
    aboutUs: wpPage(databaseId: {eq: 206}) {
      title
      aboutUs {
        aboutUsA {
          title
        }
        aboutUsB {
          title
        }
        aboutUsC {
          title
          subtitle
        }
        aboutUsD {
          title
          contentBlock {
            titleFill
            titleOutline
            subtitle
            video {
              localFile {
                publicURL
              }
            }
          }
        }
        aboutUsE {
          title
          subtitle
          benefits {
            title
          }
          buttonA {
            label
            link
          }
        }
      }
    }
  }
`;
